<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import SelectInput from '@cox2m/city-services-ui-components/src/forms/SelectInput.svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import ScalableDataTable from "@cox2m/city-services-ui-components/src/components/ScalableDataTable.svelte";
  import DateTimePicker from '@cox2m/city-services-ui-components/src/components/time/DateTimePicker.svelte';
  import axios from 'axios';

  import {getCookieByName, setErrorEventData, formatDateStringPicker} from '@cox2m/city-services-ui-components/src/funcs.js';
  import {onMount} from 'svelte';

  export let appSlug;

  const INITIAL_ITEMS_PER_PAGE = 20;
  const ONE_DAY_MS = 1000 * 60 * 60 * 24;
  const TODAY_FORMATTED_DATE = formatDateStringPicker(new Date());

  const token = getCookieByName(`${'development'}_token`);
  const headers = {
    headers: {
      Authorization: token
    }
  };

  let searchCriteria = '';
  let selectedEventType;
  let appId;

  let appEvents = [{label: 'All', value: 'all'}];
  let rows = [];

  let loading = true;
  let error = false;
  let paginationOptions = {
    limit: INITIAL_ITEMS_PER_PAGE,
    offset: 0
  };
  let paginationResponse;
  let sortString = 'createdAt:desc';
  let initialDate;
  let finalDate;
  let from;
  let to;
  let userPickedTime;

  const columns = [
    {label: 'Action', key: 'name', type: 'text', notSortable: true},
    {label: 'User Changed', key: 'userName', type: 'text', notSortable: true},
    {label: 'Changed by', key: 'user', type: 'text', notSortable: true},
    {label: 'Changes', key: 'changes', type: 'text', notSortable: true},
    {label: 'Email Changed', key: 'userChanged', type: 'text', notSortable: true},
    {label: 'Role Changed', key: 'roleUpdated', type: 'text', notSortable: true},
    {label: 'Blockage Reason', key: 'blockageReason', type: 'text', notSortable: true},
    {label: 'Occurred At', key: 'createdAt', type: 'text', notSortable: true},
    {label: 'Id', key: 'id', type: 'text', notSortable: true},
  ];

  const setError = () => {
    error = true;
    loading = false;
  };

  const handleSearch = async (shouldSearch, e) => {
    shouldSearch || e.key === 'Enter' ? await searchLogs() : null;
  };

  const setRows = logs => {
    if (logs.length) {
      rows = logs.map(log => {
        console.info('[log]', log);
        return {
          ...log,
          "userChanged": log.data.userChanged,
          "blockageReason": log.data.blockageReason,
          "userName": log.data.userName,
          "roleUpdated": log.data.roleUpdated,
          "changes": log.data.changes,
          "user": log.data.user
        };
      });
    }
    loading = false;
  };

  const searchLogs = async () => {
    loading = true;

    try {
      const sort = sortString && `&sort=${sortString}`;
      const {data} = await axios.get(
        `https://development-smartcities-api.cox2m.com/users-logs/${appId}?${
          selectedEventType && selectedEventType !== 'all'
            ? `name=${selectedEventType}`
            : ''
        }${
          searchCriteria !== '' ? `&search=${searchCriteria}` : ''
        }${
          from !== '' ? `&from=${from}` : ''
        }${
          to !== '' ? `&to=${to}` : ''
        }&origin=user_event&limit=${paginationOptions.limit}&offset=${paginationOptions.offset}${sort}`,
        headers
      );
      const {logs, paging} = data;
      setRows(logs);
      paginationResponse = paging;
    } catch (error) {
      setErrorEventData(window.dispatchEvent, error.response, 'get-user-logs-by-app-id');
      setError(error);
    }
  };

  const callLogsInformation = (appId, from, to) => {
    const promises = [
      axios.get(
        `https://development-smartcities-api.cox2m.com/notifications/${appId}/metrics`,
        headers
      ),
      axios.get(
        `https://development-smartcities-api.cox2m.com/users-logs/${appId}?from=${from}&to=${to}&origin=user_event&limit=${paginationOptions.limit}&offset=${paginationOptions.offset}`,
        headers
      )
    ];

    Promise.all(promises)
      .then(response => {
        const [eventsResponse, logsResponse] = response;
        appEvents = eventsResponse.data.map(event =>
          ({label: event.name, value: event.name})
        );
        const {logs, paging} = logsResponse.data;
        setRows(logs);
        paginationResponse = paging;
      })
      .catch(error => {setError(error); setErrorEventData(window.dispatchEvent, error.response, 'get-notifications-metrics-by-app-id and get-user-logs-by-app-id');});
  };

  onMount(async () => {
    try {
      const {data} = await axios.get(
        `https://development-smartcities-api.cox2m.com/apps/${appSlug}`,
        headers
      );
      appId = data.appId;
      initialDate = new Date(new Date() - ONE_DAY_MS);
      finalDate = new Date();
      from = toIsoString(initialDate);
      to = toIsoString(finalDate);

      callLogsInformation(appId, from, to);
    } catch (error) {
      setErrorEventData(window.dispatchEvent, error.response)
      setError(error);
    }
  });

  const handleFetchDataEvent = async e => {
    loading = true;
    paginationOptions = e.detail.dataPositioning;
    await searchLogs();
  };

  const updateSortingField = async e => {
    sortString = e.detail.sortString;
    await searchLogs();
  };

  /**
   * Handle the date time picker component selection and run the search
   * @param e
   * @returns {Promise<void>}
   */
  const handleDateTimePickerChange = async e => {
    e.detail.latestValidFirstSelectedDate && (initialDate = e.detail.latestValidFirstSelectedDate);
    e.detail.latestValidLatestSelectedDate && (finalDate = e.detail.latestValidLatestSelectedDate);

    from = formatIsoDate(initialDate, e.detail.selectedStartTime);
    to = formatIsoDate(finalDate, e.detail.selectedEndTime);
    userPickedTime = {
      selectedStartTime: e.detail.selectedStartTime,
      selectedEndTime: e.detail.selectedEndTime,
    }
    await searchLogs();
  }

  /**
   * Format a given date and time in iso format
   * @param date
   * @param selectedTime
   * @returns {`${string}T${*}`}
   */
  const formatIsoDate = (date, selectedTime) => {
    const timeIsoFormat = selectedTime.isoFormat;
    const index = timeIsoFormat.indexOf('-');
    let time = timeIsoFormat.substring(0, index);
    if (selectedTime.selectedHour % 12 === 0) {
      time = time.replace('12', '00').replace('24', '12');
    }
    return `${formatDateStringPicker(new Date(date))}T${time}`;
  }

  /**
   * Convert a given date into an ISO String without Z
   * @param date
   * @returns {*}
   */
  const toIsoString = (date) => {
    return date.toISOString().replace('Z', '')
  }
</script>

<style>
  .absolute-center {
    position: absolute;
    top: 50%;
    left: calc(50% - 30px);
  }
  :global(.filters-container .form-control) {
    max-width: 500px;
  }

  .filter-item {
    display: flex;
    align-items: center;
    max-width: 500px;
  }
  .filter-label {
    padding-right: var(--cox2m-spacing-4-units);
  }
</style>

{#if loading}
  <div class="absolute-center d-inline-block">
    <LoadingSpinner />
  </div>
{:else if error}
  <div class="w-100 text-center mt-5">
    <h3>We are sorry, we could not fetch the application logs</h3>
  </div>
{:else}
  <div class="p-3 px-4">
    <h2 class="mb-4">Monitoring Log</h2>
    <div class="filters-container">
      <TextInput
        id="logs-table-search-input"
        icon="search"
        customContainerClass="shadow-sm mb-2"
        placeholder="search by event data"
        bind:value={searchCriteria}
        onKeyPress={e => handleSearch(false, e)} />
      <div class="filter-item">
        <strong class="filter-label">Event type:</strong>
        <SelectInput
          onChange={async (e) => {
              selectedEventType = e.currentTarget.value;
              await handleSearch(true, e);
            }}
          value={selectedEventType}
          options={appEvents}
          id="logs-table-event-type-select" />
      </div>
      <div class="filter-item">
        <DateTimePicker
          on:confirm-selection-change={handleDateTimePickerChange}
          firstSelectedDate={formatDateStringPicker(initialDate)}
          latestSelectedDate={formatDateStringPicker(finalDate)}
          endCalendarAt={TODAY_FORMATTED_DATE}
          initialStartTime={userPickedTime ? userPickedTime.selectedStartTime.userFriendlyFormat : '12:00 am'}
          initialEndTime={userPickedTime ? userPickedTime.selectedEndTime.userFriendlyFormat : '11:59 pm'}
          timeRange={true} />
      </div>
    </div>
    {#if rows.length}
      <div class="mt-5">
        <ScalableDataTable
          id="log-table"
          on:fetchData={handleFetchDataEvent}
          on:sortRequestByField={updateSortingField}
          {columns}
          pagination
          isLoading={loading}
          {paginationResponse}
          {rows}
          fieldSelectionKey="id"
          dataPositioning={paginationOptions}
          itemsPerPage={paginationOptions.limit}
          highlightRow={false}
          sort={sortString}
        />
      </div>
    {:else}
      <div class="w-100 text-center mt-5">
        <h3>There are no logs for this selected search</h3>
      </div>
    {/if}
  </div>
{/if}
