<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MuiTextInput from '@cox2m/city-services-ui-components/src/forms/MuiTextInput.svelte';
  import ForgotPasswordEmailConfirmation from "./ForgotPasswordEmailConfirmation.svelte";
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs.js';
  import {user} from '../../../stores';

  import {forgotPassword, getActiveDirectoryDomains, getUserDomain} from 'web-actions';
  import {cleanCookies} from '../../../funcs';
  import {onMount, onDestroy} from 'svelte';
  import {EMAIL_REGEX} from 'web-constants';
  import {push} from 'svelte-spa-router';
  import {signIn} from 'web-auth';

  import {EventType} from "@azure/msal-browser";

  export let recoveryLinkSent = false;

  const cox2mLatestVisitedPage = getCookieByName(`${'development'}_cox2mlatesturl`);
  let activeDirectoryHighlight = false;
  let activeDirectoryHighlightTimeout;
  let activeDirectoryDomainsResponse;
  let email = '';
  let loading = false;

  $: hasLoginInformation = email.trim().length !== 0;

  let errorMessage = '';
  let isEmailCorrect = true;

  $: isInformationValid = EMAIL_REGEX.test(email);

  const validateLoginInformation = () => {
    clearErrors();
    if (email.trim() === '') {
      errorMessage = 'Please type an email address.';
      isEmailCorrect = false;
    } else if (!EMAIL_REGEX.test(email)) {
      errorMessage = 'Please type a valid email address.';
      isEmailCorrect = false;
    } else {
      errorMessage = '';
    }
  };

  const clearErrors = () => {
    isEmailCorrect = true;
  };

  const handleTextChange = () => {
    clearErrors();
  };

  const handleResetPasswordClick = async () => {
    validateLoginInformation();
    if (isInformationValid) {
      loading = true;
      const domainResponse = await getUserActiveDirectory() || await isEmailSupportedByActiveDirectory();
      if (domainResponse) {
        await activeDirectoryHandler(domainResponse);
      } else {
        const requestResponse = await forgotPassword({email});
        if (requestResponse.fulfilled) {
          cleanCookies({shouldRemoveLatestVisitedUrl: true});
          user.logout();
          showForgotPasswordMessage();
        } else {
          errorMessage = requestResponse.message;
        }
        loading = false;
      }
    }
  };

  const getUserActiveDirectory = async () => {
    const {fulfilled, ...data} = await getUserDomain(email);
    if (fulfilled) {
      return data;
    } else {
      loading = false;
      errorMessage = data.message;
    }
    return false;
  };

  /**
   * Checks if the email domain is supported by Active directory existing domains
   * @returns {*}
   */
  const isEmailSupportedByActiveDirectory = () => {
    let emailDomain = email.split('@')[1].trim().toLowerCase();
    return activeDirectoryDomainsResponse && activeDirectoryDomainsResponse.response &&
      activeDirectoryDomainsResponse.response.find(element => emailDomain.includes(element.domain));
  };

  const activeDirectoryHandler = async (domain) => {
    loading = false;
    activeDirectoryHighlight = true;

    errorMessage =
      'Your organization uses Active Directory integration for login. Please go back to our login form or log in with active directory using the box that popped up in your screen';
    await handleLoginWithActiveDirectoryClick(domain.systemUsername);
  };

  const handleReturnToLoginClick = () => {
    push('/start');
  };

  const showForgotPasswordMessage = () => {
    recoveryLinkSent = true;
  };

  const getDomains = async () => {
    activeDirectoryDomainsResponse = await getActiveDirectoryDomains();
  };

  onMount(() => {
    getDomains();
    email = getCookieByName(`${'development'}_rememberme`);
  });

  const handleLoginWithActiveDirectoryClick = async (aadClientId) => {
    loading = true;
    let oneSignalInfo = {};
    if (navigator.userAgent.indexOf('gonative') > -1) {
      oneSignalInfo = await window.gonative.onesignal.onesignalInfo();
    }
    const {fulfilled} = await signIn(aadClientId, email, message => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        window.location.reload();
      }
    }, oneSignalInfo);

    if (fulfilled) {
      if (cox2mLatestVisitedPage !== null && cox2mLatestVisitedPage !== '') {
        window.location.href = cox2mLatestVisitedPage;
      } else {
        push('/');
      }
    } else {
      push('/start');
    }
  };

  onDestroy(() => {
    clearTimeout(activeDirectoryHighlightTimeout);
  });
</script>

<style>
  p {
    color: var(--cox2m-clr-neutral-500);
  }

  .login-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: clamp(200px, 250px, 300px)
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
  }

  .anchor-container {
    height: var(--cox2m-spacing-11-units);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: clamp(var(--cox2m-spacing-4-units), var(--cox2m-spacing-6-units), var(--cox2m-spacing-8-units))
  }

  .error-message {
    color: var(--cox2m-clr-critical-500);
    font-size: clamp(var(--cox2m-fs-1300), var(--cox2m-fs-1200), var(--cox2m-fs-1000)) !important;
  }

  .paragraph-button {
    color: var(--cox2m-clr-brand-500);
    text-decoration: none;
    margin-left: calc(var(--cox2m-spacing-1-units) + 1px);
  }

  .paragraph-button:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  :global(.active-directory-disable div, .active-directory-disable button) {
    opacity: 0.5;
  }

  label {
    font-size: clamp(var(--cox2m-fs-1300), var(--cox2m-fs-1200), var(--cox2m-fs-1000)) !important;
    align-self: flex-start;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
</style>

{#if !recoveryLinkSent}
<div class="login-container">
  <div class="form-container">
    <label for="email">We’ll send a recovery link to</label>
    <MuiTextInput
      id="email"
      name="email"
      label="Email"
      bind:value={email}
      disabled={activeDirectoryHighlight}
      customContainerClass="w-100"
      onChange={handleTextChange}
      inputError={!isEmailCorrect} />
    {#if errorMessage && errorMessage !== ''}
      <p class="error-message">{errorMessage}</p>
    {/if}
  </div>
  <div class={activeDirectoryHighlight ? 'active-directory-disable' : ''}>
    {#if loading}
      <LoadingSpinner />
    {:else}
      <Button
        id="reset-password-button"
        text="Send recovery link"
        on:click={handleResetPasswordClick}
        disabled={activeDirectoryHighlight}
        backgroundColor="var(--cox2m-clr-brand-600)"
        customClass="cox2m-shadow-1-dp {hasLoginInformation && isInformationValid ? 'active' : ''}" />
    {/if}
  </div>
  <div class="anchor-container fs-1000">
    <p class="paragraph-button fs-1000" on:click={handleReturnToLoginClick}>
      Return to log in
    </p>
  </div>
</div>
{:else if recoveryLinkSent}
  <ForgotPasswordEmailConfirmation {email} />
{/if}
