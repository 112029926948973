<script>
  import SidebarAlertCreation from "../../components/alerts/components/SidebarAlertCreation.svelte";
  import SidebarAlertView from "../../components/alerts/components/SidebarAlertView.svelte";
  import LoadingSpinner from "../../components/LoadingSpinner.svelte";
  import Icon from "../../components/Icon.svelte";
  import axios from "axios";

  import { getCookieByName, setErrorEventData } from "../../funcs";
  import { createEventDispatcher, onMount } from "svelte";

  export let appName = null;
  export let id = null;

  const token = getCookieByName(`${"development"}_token`);
  const dispatch = createEventDispatcher();

  let fetchNotificationsError = false;
  let notificationsCollection = [];
  let openCreateSide = false;
  let userHasDevices = false;
  let openViewSide = false;
  let selectedNotification;
  let userPhoneNumber;
  let loading = true;


  const headers = {
    headers: {
      Authorization: token,
    },
  };

  const userPhoneNumberFetch = async () => {
    try {
      const { data } = await axios.get(`https://development-smartcities-api.cox2m.com/users/`, headers);
      userHasDevices = data.devices && data.devices.length;
      userPhoneNumber = data.phoneNumber;
    } catch (error) {
      setErrorEventData(window.dispatchEvent, error.response, "get-user");
    }
  };

  const getApplicationId = async () => {
    if (appName && !id) {
      try {
        const { data } = await axios.get(
          "https://development-smartcities-api.cox2m.com/apps?limit=100&offset=0",
          headers
        );
        id = data.apps.find(
          (app) => app.name === appName || app.slug === appName.toLowerCase()
        ).appId;
        handleApplicationIdChange(id);
      } catch (error) {
        setErrorEventData(window.dispatchEvent, error.response, "get-apps");
        fetchNotificationsError = true;
      }
    } else {
      handleApplicationIdChange(id);
    }
  };

  onMount(() => {
    getApplicationId();
  });

  $: handleApplicationIdChange(id);

  const handleApplicationIdChange = async (id) => {
    if (id) {
      loading = true;
      selectedNotification = null;

      try {
        const allSubscriptionsResponse = await axios.get(
          "https://development-smartcities-api.cox2m.com/notifications/settings",
          headers
        );
        const eventTypesResponse = await axios.get(
          `https://development-smartcities-api.cox2m.com/notifications/${id}/metrics`,
          headers
        );

        const userSubscriptions = allSubscriptionsResponse.data.filter(
          (notification) => notification.clientId === token.substring(0, 8)
        );

        notificationsCollection = await eventTypesResponse.data.reduce(
          async (accumulatorPromise, current, index) => {
            let accumulator = await accumulatorPromise;
            const additionalFilters = await axios.get(
              `https://development-smartcities-api.cox2m.com/events/filters/${current.id}`,
              headers
            );
            accumulator[index] = {
              ...current,
              additionalFilters: additionalFilters.data,
              subscriptions: userSubscriptions.filter(
                (currentSubscription) =>
                  currentSubscription.eventDefinition.id === current.id
              ),
              subscribed:
                userSubscriptions.findIndex(
                  (currentSubscription) =>
                    currentSubscription.eventDefinition.id === current.id
                ) !== -1,
            };
            return accumulator;
          },
          []
        );

        loading = false;
      } catch (error) {
        setErrorEventData(
          window.dispatchEvent,
          error.response,
          "get-all-notifications-configuration and get-notifications-metrics-by-app-id"
        );
        fetchNotificationsError = true;
        loading = false;
      }
    } else {
      getApplicationId();
    }
  };

  const handleNotificationSelection = (notification) => {
    openViewSide = true;
    openCreateSide = false;
    selectedNotification = notification;
  };

  const createSidebarShow = () => {
    openCreateSide = true;
  };

  const closeCreationSide = () => {
    openCreateSide = false;
  };

  const closeViewSide = () => {
    openViewSide = false;
  };

  userPhoneNumberFetch();
</script>

{#if loading && !fetchNotificationsError}
  <div class="loading-container">
    <div>
      <LoadingSpinner />
    </div>
  </div>
{:else if !fetchNotificationsError}
  {#if notificationsCollection.length}
    <SidebarAlertCreation
      phoneNumber={userPhoneNumber}
      open={openCreateSide}
      close={closeCreationSide}
      notification={selectedNotification}
      onSuccess={handleApplicationIdChange}
      {userHasDevices}
      on:alertCreationOrDeletion={dispatch("alertCreationOrDeletion")}
    />
    {#if selectedNotification}
      <SidebarAlertView
        refreshFunction={handleApplicationIdChange}
        phoneNumber={userPhoneNumber}
        {createSidebarShow}
        open={openViewSide}
        close={closeViewSide}
        notification={selectedNotification}
        {userHasDevices}
        on:alertCreationOrDeletion={() => dispatch("alertCreationOrDeletion")}
      />
    {/if}
    <div
      data-testid="notification-events-container"
      id="notification-events-container"
    >
      {#each notificationsCollection as notification}
        <div
          class="notification-container"
          id={notification.id + "-container"}
          data-testid={notification.id + "-container"}
          on:click={() => handleNotificationSelection(notification)}
        >
          <span class="notification-name d-flex">
            <Icon
              icon={notification.subscribed ? "bell" : "bell-slash"}
              id={notification.id + "-subscribed-icon"}
              size="calc(var(--cox2m-spacing-5-units) + var(--cox2m-visual-corrector))"
              color="var(--cox2m-clr-info-900)"
              className="btn p-0 m-0"
            />
            {notification.name.toUpperCase()}
          </span>
          {#if notification.summary}
            <p class="notification-summary">{notification.summary}</p>
          {/if}
        </div>
      {/each}
    </div>
  {:else}
    <div class="error-container">
      <div>
        <h2
          class="error-message"
          id="empty-notifications-message"
          data-testid="empty-notifications-message"
        >
          There are no event notifications for this application yet.
        </h2>
      </div>
    </div>
  {/if}
{:else}
  <div class="error-container">
    <div>
      <h2
        class="error-message"
        id="notifications-fetching-error-message"
        data-testid="notifications-fetching-error-message"
      >
        We are sorry! please refresh the page or try again later
      </h2>
    </div>
  </div>
{/if}

<style>
  .loading-container,
  .error-container {
    width: 100%;
    height: 80vh;
    margin-top: var(--cox2m-spacing-8-units);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error-message {
    color: var(--cox2m-clr-neutral-700);
  }
  .notification-container {
    cursor: pointer;
    max-width: 600px;
    min-height: var(--cox2m-spacing-16-units);
    padding: var(--cox2m-spacing-4-units);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
    margin: var(--cox2m-spacing-4-units) 0;
    border-radius: 3px;
    background: var(--cox2m-clr-neutral-white);
  }
  .notification-container:hover {
    background-color: var(--cox2m-clr-neutral-200);
  }
  .notification-summary {
    margin: 0 0 0 calc(var(--cox2m-spacing-8-units) - var(--cox2m-visual-corrector));
    color: var(--cox2m-clr-neutral-700);
  }
  .notification-name {
    display: flex;
    column-gap: var(--cox2m-spacing-2-units);
  }
</style>
