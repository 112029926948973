<script>
  import LoadingSpinner from '../../LoadingSpinner.svelte';
  import Overlay from '../../Overlay.svelte';
  import Button from '../../Button.svelte';
  import Icon from '../../Icon.svelte';
  import axios from 'axios';

  import {
    getCookieByName,
    setSuccessToast,
    setErrorEventData
  } from '../../../funcs';
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();
  const token = getCookieByName(`${'development'}_token`);
  const headers = {
    headers: {
      Authorization: token
    }
  };

  export let open = false;
  export let data = {};

  let loading = false;

  const handleUpdateStatus = async () => {
    try {
      loading = true;
      let ids = data.subscriptions.map(subscription => {
        return subscription.id;
      });

      await axios.put(
        `https://development-smartcities-api.cox2m.com/notifications-settings/status`,
        {
          ids,
          status: data.is_active ? 'inactive' : 'active'
        },

        headers
      );

      open = false;

      dispatch('toggle-status-success', {
        ids,
        status: !data.is_active
      });
      await setSuccessToast(
        window.dispatchEvent,
        `Alert${ids.length > 1 ? "s" : ""} updated successfully`
      );
    } catch (error) {
      setErrorEventData(
        window.dispatchEvent,
        error.response,
        'put-notification-setting-status'
      );
    }
    loading = false;
  };
</script>

<style>
  .alert-activation-toggle-modal {
    background-color: var(--cox2m-clr-neutral-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    width: min(500px, 100%);
    max-height: 80vh;
    overflow: auto;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-2-units);
    color: var(--cox2m-clr-neutral-600);
    font-weight: var(--cox2m-fw-bold);
    border-bottom: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-600);
    padding: var(--cox2m-spacing-5-units) var(--cox2m-spacing-6-units);
    font-size: var(--cox2m-fs-700);
  }
  .disclaimer-container {
    padding: var(--cox2m-spacing-5-units);
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--cox2m-spacing-3-units);
    margin-bottom: var(--cox2m-spacing-8-units);
    padding-right: var(--cox2m-spacing-5-units);
  }
  .loading-container {
    display: grid;
    place-content: center;
    height: 120px;
  }
</style>

{#if open}
  <Overlay on:click={() => (open = false)}>
    <div
      class="alert-activation-toggle-modal"
      id={'alert-activation-toggle-modal'}
      data-testid={'alert-activation-toggle-modal'}>
      <div class="title-container">
        <Icon
          icon="info"
          color="var(--cox2m-clr-brand-600)"
          size="var(--cox2m-spacing-6-units)" />
        <span>Confirm action</span>
      </div>
      {#if loading}
        <div class="loading-container">
          <LoadingSpinner />
        </div>
      {:else}
        <div class="disclaimer-container">
          <span>
            Are you sure you want to {data.is_active ? 'enable' : 'disable'} {data.modify_all ? 'all the alerts of this type' : 'this alert'}?
          </span>
        </div>

        <div class="buttons-container">
          <Button
            id={data.id + '-delete-cancel-button'}
            text="Cancel"
            backgroundColor="var(--cox2m-clr-neutral-white)"
            color="var(--cox2m-clr-brand-600)"
            justifyContent="between"
            borderColor="var(--cox2m-clr-brand-600)"
            maxWidth="90px"
            height="32px"
            iconSize="calc(var(--cox2m-spacing-4-units) +
            var(--cox2m-visual-corrector))"
            on:click={() => dispatch('close-modal')} />

          <Button
            id={data.id + '-delete-confirm-button'}
            text="Confirm"
            backgroundColor="var(--cox2m-clr-brand-600)"
            justifyContent="between"
            maxWidth="100px"
            height="32px"
            iconSize="calc(var(--cox2m-spacing-4-units) +
            var(--cox2m-visual-corrector))"
            on:click={() => handleUpdateStatus()} />
        </div>
      {/if}
    </div>
  </Overlay>
{/if}
