<script>
  import LoadingSpinner from '../../LoadingSpinner.svelte';
  import Button from '../../Button.svelte';
  import Icon from '../../Icon.svelte';
  import axios from 'axios';

  import {
    getCookieByName,
    setErrorEventData,
    setSuccessToast
  } from '../../../funcs';
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  const headers = {
    headers: {
      Authorization: getCookieByName(`${'development'}_token`)
    }
  };

  export let creationData = null;
  export let alertData = null;
  export let builtData = {};
  export let alertToEdit = null;

  let loading = false;

  const getConditionLabel = operator => {
    switch (operator) {
      case '>':
        return 'greater than';
      case '<':
        return 'lower than';
      case '>=':
        return 'greater than or equal to';
      case '<=':
        return 'lower than or equal to';
      case '==':
        return 'equal to';
    }
  };

  const getConditionLabelForAdditionalFilters = (operator, type) => {
    if (type === 'STRING') {
      switch (operator) {
        case '==':
          return 'is equal to';
        case '!=':
          return 'is different than';
        case '=~':
          return 'contains';
        default:
          return '';
      }
    } else if (type === 'NUMBER' || type === 'DATE') {
      switch (operator) {
        case '==':
          return 'is equal to';
        case '<=':
          return 'is minor than';
        case '>=':
          return 'is greater than';
        default:
          return '';
      }
    } else if (type === 'MULTI_SELECT') {
      switch (operator) {
        case '=~':
          return 'is equal to';
        default:
          return '';
      }
    }
  };

  const alertCreationHandler = async () => {
    loading = true;

    try {
      await axios.post(
        `https://development-smartcities-api.cox2m.com/notifications/settings`,
        {
          ...builtData
        },
        headers
      );
      await setSuccessToast(window.dispatchEvent, 'Alert created Successfully');
      dispatch('creation-success');
    } catch (error) {
      setErrorEventData(
        window.dispatchEvent,
        error.response,
        'post-notification-configuration'
      );
    }
    loading = false;
  };

  const alertUpdateHandler = async () => {
    loading = true;

    try {
      await axios.patch(
        `https://development-smartcities-api.cox2m.com/notifications/settings/${alertToEdit.id}`,
        {
          ...builtData
        },
        headers
      );
      await setSuccessToast(window.dispatchEvent, 'Alert updated Successfully');
      dispatch('creation-success');
    } catch (error) {
      setErrorEventData(
        window.dispatchEvent,
        error.response,
        'post-notification-configuration'
      );
    }
    loading = false;
  };
</script>

<style>
  .alert-creation-confirmation-modal {
    background-color: var(--cox2m-clr-neutral-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    width: min(500px, 100%);
    max-height: 80vh;
    overflow: auto;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-2-units);
    color: var(--cox2m-clr-neutral-700);
    font-weight: var(--cox2m-fw-bold);
    border-bottom: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
    padding: var(--cox2m-spacing-5-units) var(--cox2m-spacing-6-units);
    font-size: var(--cox2m-fs-700);
  }

  .disclaimer-container {
    padding: var(--cox2m-spacing-5-units);
    padding: var(--cox2m-spacing-5-units);
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--cox2m-spacing-3-units);
    margin-block: var(--cox2m-spacing-8-units);
    padding-right: var(--cox2m-spacing-5-units);
  }

  ul {
    margin: 0;
    padding-inline: 0;
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
    border-radius: 12px;
  }
  li {
    list-style: none;
    padding: var(--cox2m-spacing-4-units);
    border-bottom: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
  }
  li:last-of-type {
    border-bottom: none;
  }

  .list-item-content-container,
  .list-item-content-container span {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-3-units);
  }

  .options-list-container {
    padding: var(--cox2m-spacing-5-units);
  }
  .loading-container {
    display: grid;
    place-content: center;
    height: 200px;
  }
</style>

<div
  class="alert-creation-confirmation-modal"
  id={alertData.notification.id + '-alert-creation-confirmation-modal'}
  data-testid={alertData.notification.id + '-alert-creation-confirmation-modal'}>
  <div class="title-container">
    <Icon
      icon="info"
      color="var(--cox2m-clr-brand-600)"
      size="var(--cox2m-spacing-6-units)" />
    <span>Confirm action</span>
  </div>
  {#if loading}
    <div class="loading-container">
      <LoadingSpinner />
    </div>
  {:else}
    <div class="disclaimer-container">
      <span>
        Are you sure you want to {alertToEdit ? 'edit' : 'create'} this alert
        for the
        <b>{alertData.notification.name}</b>
        entry with the following options?
      </span>

    </div>
    <div class="options-list-container">
      <ul>
        <li>
          <div class="list-item-content-container">
            <span
              id="confirmation-frequency"
              data-testid="confirmation-frequency">
              Reference name:
              <b>{creationData.name}</b>
            </span>
          </div>
        </li>
        <li>
          <div class="list-item-content-container">
            <span
              id="confirmation-frequency"
              data-testid="confirmation-frequency">
              Receive alerts:
              <b>{creationData.frequency.label}</b>
            </span>
          </div>
        </li>
        {#if creationData.frequency.value === 'IMMEDIATELY'}
          <li>
            <div class="list-item-content-container">
              <span id="confirmation-snooze" data-testid="confirmation-snooze">
                Snooze between repeated alerts:
                <b>{creationData.snooze.label}</b>
              </span>
            </div>
          </li>
        {/if}
        {#if creationData.frequency.locations && creationData.frequency.locations.length}
          <li>
            <div class="list-item-content-container">
              <span
                id="confirmation-locations"
                data-testid="confirmation-locations">
                Location:
                <b>{creationData.locations.join(', ')}</b>
              </span>
            </div>
          </li>
        {/if}
        <li>
          <div class="list-item-content-container">
            <span>
              Dashboard notification:
              <Icon
                icon={creationData.via.dashboard ? 'check-circle' : 'cross-circle'}
                id="confirmation-dashboard-notifications-icon"
                size="var(--cox2m-spacing-5-units)"
                color={creationData.via.dashboard ? 'var(--cox2m-clr-success-500)' : 'var(--cox2m-clr-critical-500)'} />
            </span>
          </div>
        </li>
        <li>
          <div class="list-item-content-container">
            <span>
              Email notification:
              <Icon
                icon={creationData.via.email ? 'check-circle' : 'cross-circle'}
                id="confirmation-email-notifications-icon"
                size="var(--cox2m-spacing-5-units)"
                color={creationData.via.email ? 'var(--cox2m-clr-success-500)' : 'var(--cox2m-clr-critical-500)'} />
            </span>
          </div>
        </li>
        <li>
          <div class="list-item-content-container">
            <span>
              Phone app notification:
              <Icon
                icon={creationData.via.sms ? 'check-circle' : 'cross-circle'}
                id="confirmation-sms-notifications-icon"
                size="var(--cox2m-spacing-5-units)"
                color={creationData.via.sms ? 'var(--cox2m-clr-success-500)' : 'var(--cox2m-clr-critical-500)'} />
            </span>
          </div>
        </li>
        {#if creationData.selectedCondition}
          <li>
            <div class="list-item-content-container">

              <span>
                When:
                <strong
                  data-testid="confirmation-selected-condition"
                  id="confirmation-selected-condition">
                  {getConditionLabel(creationData.selectedCondition)}
                </strong>
              </span>
            </div>
          </li>
          <li class="list-group-item">
            <span>
              Threshold Value:
              <strong
                data-testid="confirmation-selected-threshold"
                id="confirmation-selected-threshold">
                {creationData.threshold}
              </strong>
            </span>
          </li>
        {/if}
        {#if alertData.notification.additionalFilters && alertData.notification.additionalFilters.length}
          <li>
            <div class="list-item-content-container">

              <strong
                data-testid="confirmation-additional-filters"
                id="confirmation-additional-filters">
                Additional Filters
              </strong>
            </div>
          </li>
          {#each builtData.additionalFilters as additionalFilter}
            <li>
              <div class="list-item-content-container">
                When {additionalFilter.eventDefinitionFilter.label} :
                <strong
                  data-testid="confirmation-selected-condition"
                  id="confirmation-selected-condition">
                  {getConditionLabelForAdditionalFilters(additionalFilter.condition, additionalFilter.eventDefinitionFilter.type)}
                  {additionalFilter.threshold || ''}
                </strong>
              </div>
            </li>
          {/each}
        {/if}
      </ul>
    </div>
    <div class="buttons-container">
      <Button
        id={alertData.notification.id + '-add-alert-button'}
        text="Cancel"
        backgroundColor="var(--cox2m-clr-neutral-white)"
        color="var(--cox2m-clr-brand-600)"
        justifyContent="between"
        borderColor="var(--cox2m-clr-brand-600)"
        maxWidth="90px"
        height="32px"
        iconSize="calc(var(--cox2m-spacing-4-units) +
        var(--cox2m-visual-corrector))"
        on:click={() => dispatch('close-modal')} />

      <Button
        id={alertData.notification.id + '-add-alert-button'}
        text="Confirm"
        backgroundColor="var(--cox2m-clr-brand-600)"
        justifyContent="between"
        maxWidth="100px"
        height="32px"
        iconSize="calc(var(--cox2m-spacing-4-units) +
        var(--cox2m-visual-corrector))"
        on:click={() => {
          alertToEdit ? alertUpdateHandler() : alertCreationHandler();
        }} />
    </div>
  {/if}
</div>
