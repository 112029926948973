<style>
  .main-dashboard-container {
    overflow: auto;
    background-color: var(--cox2m-clr-neutral-200);
    min-height: 100%;
    padding: var(--cox2m-spacing-8-units);
  }

  @media (max-width: 767.98px) {
    .main-dashboard-container {
      padding: var(--cox2m-spacing-4-units);
    }
  }
</style>

<div class="main-dashboard-container">
  <slot />
</div>
