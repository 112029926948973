<script>
  import AlertPreview from "./AlertsPreview.svelte";
  import Button from "../../Button.svelte";
  import Icon from "../../Icon.svelte";

  import { ALERT_SEVERITY_INDICATORS } from "../../../constants";
  import { sendWebsocketMessage } from "../../../funcs";
  import { onMount, onDestroy } from "svelte";

  export let smartCitiesUrl = false;
  export let appSlug = null;
  export let token;

  const webSocketHost =
    "wss://https://development-smartcities-api.cox2m.com/ws/notifications".replace("https://", "") +
    (appSlug ? `?appSlug=${appSlug}` : "");
  const authMessage = `{"value" : "${token}"}`;

  let expanded = false;
  let screenWidth;

  let eventsWebsocket = null;
  let eventList = [];
  let sortedEventList = [];
  let currentMaximumSeverityFeatures = ALERT_SEVERITY_INDICATORS.info;

  const sortEventsList = (removeId) => {
    sortedEventList = [
      ...eventList.filter(({ severity }) => severity === "alert"),
      ...eventList.filter(({ severity }) => severity === "warning"),
      ...eventList.filter(({ severity }) => severity === "info"),
    ].filter(
      (sortedEvent) =>
        sortedEvent.status !== "read" &&
        ((removeId && sortedEvent.id !== removeId) || !removeId)
    );
  };

  const socketMessageHandler = (event) => {
    let eventData = JSON.parse(event.data);

    if (eventData.status !== "read") {
      eventList = [...eventList, eventData];

      sortEventsList();
    }
  };

  const detectMaximumSeverityAlert = () => {
    sortEventsList();
    if (sortedEventList.length) {
      currentMaximumSeverityFeatures =
        ALERT_SEVERITY_INDICATORS[sortedEventList[0].severity];
    } else {
      currentMaximumSeverityFeatures = ALERT_SEVERITY_INDICATORS.info;
    }
  };

  export const setEventsWebsocket = ({
    eventsWebsocket = null,
    socketUrl,
    authMessage,
    onMessageHandler,
  }) => {
    eventsWebsocket && eventsWebsocket.close();

    const webSocketHost = socketUrl;
    eventsWebsocket = new WebSocket(webSocketHost);

    eventsWebsocket.onopen = () => {
      sendWebsocketMessage(eventsWebsocket, authMessage);
    };

    eventsWebsocket.onmessage = (event) => {
      onMessageHandler(event);
    };

    eventsWebsocket.onclose = () => {
      setTimeout(() => {
        eventList = [];
        sortedEventList = [];
        setEventsWebsocket({
          eventsWebsocket: eventsWebsocket,
          socketUrl,
          authMessage,

          onMessageHandler,
        });
      }, 3000);
    };

    return eventsWebsocket;
  };

  onMount(() => {
    eventsWebsocket = setEventsWebsocket({
      eventsWebsocket: eventsWebsocket,
      socketUrl: webSocketHost,
      authMessage: authMessage,
      onMessageHandler: socketMessageHandler,
    });
  });

  onDestroy(() => {
    eventsWebsocket.onclose = () => {};
    eventsWebsocket && eventsWebsocket.close();
    eventsWebsocket = null;
  });

  $: detectMaximumSeverityAlert(sortedEventList);
</script>

<svelte:window bind:innerWidth={screenWidth} />

{#if sortedEventList.length}
  <div
    id="alerts-banner"
    class="cox2m-shadow-1-dp {sortedEventList[0].severity}"
    style="--backgroundColor: {currentMaximumSeverityFeatures.backgroundBannerColor};"
    class:add-bg={!expanded}
      on:click|self={() => {
        if(!expanded){
          if (screenWidth < 1200) {
            if (smartCitiesUrl) {
              window.location.href = `https://development-smartcities.cox2m.com/#/notifications`;
              // window.location.href = `/#/alerts/${appSlug}`;
            } else {
              window.location.href = "https://development-smartcities.cox2m.com/#/notifications";
              // window.location.href = '/#/alerts';
            }
          } else {
            expanded = !expanded;
          }
        }
      }}
  >
    <div
      class="preview-container cursor-pointer"
      on:click|self={() => {
        if (screenWidth < 1200) {
          if (smartCitiesUrl) {
            window.location.href = `https://development-smartcities.cox2m.com/#/notifications`;
            // window.location.href = `/#/alerts/${appSlug}`;
          } else {
            window.location.href = "https://development-smartcities.cox2m.com/#/notifications";
            // window.location.href = '/#/alerts';
          }
        } else {
          expanded = !expanded;
        }
      }}
    >
      <div class="title-container">
        <Icon
          icon={currentMaximumSeverityFeatures.icon}
          color={currentMaximumSeverityFeatures.iconColor}
          size="var(--cox2m-spacing-6-units)"
        />
        <span>
          {sortedEventList.length} Issue{sortedEventList.length > 0 ? "s" : ""}
        </span>
      </div>
      <div>
        <Icon
          icon={screenWidth < 1200
            ? "chevron-right"
            : expanded
            ? "chevron-up"
            : "chevron-down"}
          className="cursor-pointer"
          size="var(--cox2m-spacing-6-units)"
          color="var(--cox2m-clr-neutral-black)"
        />
      </div>
    </div>

    {#if expanded}
      <div class="alerts-content-container">
        {#each sortedEventList.slice(0, 3) as listEvent}
          <AlertPreview
            {smartCitiesUrl}
            on:updated-alert-status={(e) => {
              eventList = eventList.filter((event) => event.id !== e.detail);
              sortEventsList(e.detail);
            }}
            alertEvent={listEvent}
            {token}
          />
        {/each}
      </div>

      <div class="w-100 d-flex justify-content-end align-items-center">
        {#if sortedEventList.length > 3}
          <span class="more-alerts-disclaimer">
            {sortedEventList.length - 3}+ alert{sortedEventList.length - 3 > 1
              ? "s"
              : ""}
          </span>
        {/if}
        <Button
          customClass="font-weight-bold"
          width="auto"
          height="var(--cox2m-spacing-8-units)"
          id="alert-details"
          margin="0 0 0 var(--cox2m-spacing-2-units)"
          backgroundColor="var(--cox2m-clr-neutral-white)"
          color="var(--cox2m-clr-neutral-black)"
          borderColor="var(--cox2m-clr-brand-400)"
          on:click={() => {
            if (smartCitiesUrl) {
              window.location.href = `https://development-smartcities.cox2m.com/#/notifications`;
              // window.location.href = `https://development-smartcities.cox2m.com/#/alerts/${appSlug}`;
            } else {
              window.location.href = `https://development-smartcities.cox2m.com/#/notifications`;
              // window.location.href = "/#/alerts";
            }
          }}
          text="View All"
        />
      </div>
    {/if}
  </div>
{/if}

<style>
  #alerts-banner {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 10px;
    padding: var(--cox2m-spacing-6-units);

    margin-bottom: var(--cox2m-spacing-8-units);
  }

  .preview-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    gap: var(--cox2m-spacing-2-units);
    font-weight: var(--cox2m-fw-bold);
    color: var(--cox2m-clr-neutral-black);
    font-size: var(--cox2m-fs-700);
    align-items: center;
  }

  .alerts-content-container {
    border-top: 1px solid var(--cox2m-clr-neutral-400);
    margin-block: var(--cox2m-spacing-5-units);
  }

  .more-alerts-disclaimer {
    color: var(--cox2m-clr-neutral-black);
    font-size: var(--cox2m-fs-900);
    font-weight: var(--cox2m-fw-bold);
  }

  #alerts-banner.add-bg {
    background-color: var(--backgroundColor, var(--cox2m-clr-neutral-white));
  }

  @media only screen and (max-width: 1200px) {
    .title-container {
      font-size: var(--cox2m-fs-8000);
    }
  }
</style>
