<div class="tab-list">
	<slot></slot>
</div>

<style>
	.tab-list {
    overflow-x: auto;
	}
  ::-webkit-scrollbar{
    height: 6px;
  }
  @media only screen and (max-width: 1200px){
    ::-webkit-scrollbar{
      display: none;
    }
  }
</style>
